export interface ErrorCorrection {
    actionType: ActionType;
    request: RequestType; //from NotificationItem
    machineId: string; //from NotificationItem
    payload: string; //from NotificationItem
}

export enum ActionType {
    CANCEL = 'CANCEL',
    RETRY = 'RETRY',
}

export enum RequestType {
    OPERATIONSTART = 'OPERATIONSTART',
    OPERATIONPAUSE = 'OPERATIONPAUSE',
    OPERATIONFINISH = 'OPERATIONFINISH',
    SETCOUNTERS = 'SETCOUNTERS',
    OPERATIONCREATE = 'OPERATIONCREATE',
    ACTIVITYSTART = 'ACTIVITYSTART',
    ACTIVITYSTOP = 'ACTIVITYSTOP',
    PRINTPALLETSHEET = 'PRINTPALLETSHEET',
    ORDERBAG = 'ORDERBAG',
    PRESETTING = 'PRESETTING',
    GETCOUNTERSFROMCBM = 'GETCOUNTERSFROMCBM',
    SIGNIN = 'SIGNIN',
    SETASSISTANTS = 'SETASSISTANTS',
    SIGNOFF_SHIFTEND = 'SIGNOFF/SHIFTEND',
    ONPREM_BACKEND_DISCONNECTED = 'ONPREM_BACKEND_DISCONNECTED',
    OTHER = 'OTHER',
}

export const CANCEL_LIST: RequestType[] = [
    RequestType.PRINTPALLETSHEET,
    RequestType.ORDERBAG,
    RequestType.SIGNIN,
    RequestType.SIGNOFF_SHIFTEND,
    RequestType.PRESETTING,
    RequestType.ONPREM_BACKEND_DISCONNECTED,
];

export const LOCAL_LIST: RequestType[] = [RequestType.OPERATIONCREATE, RequestType.ONPREM_BACKEND_DISCONNECTED];

export enum ErrorCode {
    ERR_CREATE_OPERATION = 'ERR_CREATE_OPERATION',
    ERR_GET_ORDERBAG = 'ERR_GET_ORDERBAG',
    ERR_GET_PRESETTING = 'ERR_GET_PRESETTING',
    ERR_GET_COUNTERSFROMCBM = 'ERR_GET_COUNTERSFROMCBM',
    ERR_SIGNIN = 'ERR_SIGNIN',
    ERR_SIGNOFF = 'ERR_SIGNOFF',
    ERR_ONPREM_BACKEND_DISCONNECTED = 'ERR_ONPREM_BACKEND_DISCONNECTED',
}
