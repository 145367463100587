import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { filter, Observable, timer } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { BACKEND_URL, ORGANIZATION_REQUEST_PREFIX } from '@data-terminal/data-access';
import { Machine, User } from '@data-terminal/shared-models';
import { PAGE_VISIBILITY_CHANGE, takeUntilPageVisible } from '@data-terminal/utils';

@UntilDestroy()
@Injectable({
    providedIn: 'root',
})
export class DataTerminalSessionService {
    private readonly PERSON_URL: (email: string) => string = (email) =>
        `${this.backendUrl}${this.orgRequestPrefix}person/${email}`;

    private readonly KEEP_ALIVE_URL: (userId: string) => string = (userId) =>
        `${this.backendUrl}${this.orgRequestPrefix}keepAlive/${userId}`;

    private readonly ADD_ZAIKIO_ID_URL: string = `${this.backendUrl}${this.orgRequestPrefix}addZaikioId`;

    private readonly KEEP_ALIVE_REQUEST_INTERVAL = 60 * 1000;
    private _currentUser!: User;

    constructor(
        private readonly http: HttpClient,
        @Inject(BACKEND_URL) private readonly backendUrl: string,
        @Inject(ORGANIZATION_REQUEST_PREFIX) private readonly orgRequestPrefix: string,
        @Inject(PAGE_VISIBILITY_CHANGE) public readonly visibilityChange$: Observable<boolean>
    ) {}

    public logInToDataTerminal(userId: string, userMail: string, orgName: string): Observable<User> {
        return this.http.get<User>(this.PERSON_URL(userMail)).pipe(
            tap((data) => {
                if (data.userId) {
                    this._currentUser = { ...data, zaikioId: userId };
                    this.sendZaikioIdUpdateRequest(userId, orgName);
                }
            })
        );
    }

    public getCurrentDataTerminalUser(): User {
        return this._currentUser;
    }

    public setLoggedOnMachines(machines: Machine[]): void {
        this._currentUser.userMachines = machines;
        this._currentUser.machinePermissions = machines.map((machine) => ({
            id: machine.machineId,
            userMachinePermissions:
                machine.signedOnUsers.find((user) => user.userId === this._currentUser.userId)
                    ?.userMachinePermissions || [],
        }));
    }

    public keepAlive(): void {
        if (this._currentUser) {
            this.visibilityChange$
                .pipe(
                    untilDestroyed(this),
                    filter((isVisible) => isVisible),
                    switchMap(() =>
                        timer(0, this.KEEP_ALIVE_REQUEST_INTERVAL).pipe(
                            takeUntilPageVisible(this.visibilityChange$),
                            switchMap(() => this.callKeepAlive())
                        )
                    )
                )
                .subscribe();
        }
    }

    private callKeepAlive(): Observable<boolean> {
        return this.http.get<boolean>(this.KEEP_ALIVE_URL(this._currentUser['userId']));
    }

    private sendZaikioIdUpdateRequest(zaikioId: string, orgName: string): void {
        const requestBody = {
            userId: this._currentUser?.userId || '',
            zaikioId: zaikioId,
            organizationName: orgName,
        };
        this.http.post(this.ADD_ZAIKIO_ID_URL, requestBody).subscribe();
    }
}
